"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const Constants = require("./constants");
const lib_es2015_1 = require("@hodol/webapp-common/lib-es2015");
class Apis {
    constructor() {
        this.insert = (entity) => __awaiter(this, void 0, void 0, function* () {
            return yield (0, lib_es2015_1.fetchWrapper)(`/apis/${Constants.apiPathGroup}`, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify(entity),
            }, (lib_es2015_1.defaultCodeProcessor));
        });
        this.list = (request) => __awaiter(this, void 0, void 0, function* () {
            return yield (0, lib_es2015_1.fetchWrapper)(`/apis/${Constants.apiPathGroup}?${(0, lib_es2015_1.buildURLQueries)(request)}`, {
                method: 'GET',
            }, (lib_es2015_1.defaultCodeProcessor));
        });
        this.getByKey = (entity) => __awaiter(this, void 0, void 0, function* () {
            return yield (0, lib_es2015_1.fetchWrapper)(`/apis/${Constants.apiPathGroup}/key/${entity._key}`, {
                method: 'GET',
            }, (lib_es2015_1.defaultCodeProcessor));
        });
        this.update = (entity) => __awaiter(this, void 0, void 0, function* () {
            return yield (0, lib_es2015_1.fetchWrapper)(`/apis/${Constants.apiPathGroup}/key/${entity._key}`, {
                method: 'PATCH',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify(entity),
            }, (lib_es2015_1.defaultCodeProcessor));
        });
        this.delete = (entity) => __awaiter(this, void 0, void 0, function* () {
            return yield (0, lib_es2015_1.fetchWrapper)(`/apis/${Constants.apiPathGroup}/key/${entity._key}`, {
                method: 'DELETE',
            }, (lib_es2015_1.defaultCodeProcessor));
        });
        this.listForSearchSelector = (query) => __awaiter(this, void 0, void 0, function* () {
            const resp = yield this.list({
                query,
                oauth_client_owner_key: '',
                page_size: 0,
                page_no: 0,
            });
            if (resp === null)
                return [];
            return resp.items.map(i => ({
                value: i._key,
                displayText: i.id,
                description: `key: ${i._key}, owner key: ${i.oauth_client_owner._key}`,
            }));
        });
        this.getOauthClientIdByKey = (key) => __awaiter(this, void 0, void 0, function* () {
            const resp = yield this.getByKey({ _key: key });
            if (resp === null) {
                return '';
            }
            return resp.id;
        });
        this.getByKeyForSearchSelector = (key) => __awaiter(this, void 0, void 0, function* () {
            return yield this.getOauthClientIdByKey(key);
        });
    }
}
exports.default = Apis;
