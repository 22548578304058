"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineOauthClientOwnerLogin = exports.OauthClientOwnerLogin = void 0;
const lib_es2015_1 = require("@hodol/web-component-helper/lib-es2015");
const apis_1 = require("./apis");
const lib_es2015_2 = require("@hodol/dom-helper/lib-es2015");
const lib_es2015_3 = require("@hodol/webapp-common/lib-es2015");
const Constants = require("./constants");
class OauthClientOwnerLogin extends HTMLElement {
    constructor() {
        super();
        this.apis = new apis_1.default();
        this.login = () => __awaiter(this, void 0, void 0, function* () {
            if (this.emailInput.value === '') {
                alert(`put ${Constants.subjectName} email`);
                return;
            }
            if (this.passwordInput.value === '') {
                alert(`put ${Constants.subjectName} password`);
                return;
            }
            const apiResp = yield this.apis.login({
                _key: '0',
                email: this.emailInput.value,
                password: this.passwordInput.value,
            });
            if (apiResp === null)
                return;
            location.href = apiResp;
        });
        this.style.setProperty('display', 'block');
        const isLogin = (0, lib_es2015_3.getServerProps)().email !== '';
        this.innerHTML = isLogin
            ? `<div class="is-login">You have already login!</div>`
            : `<div class="is-not-login login-form">
          <div class="login-form-field-name">Email</div>
          <div>
            <input class="email" type="email" placeholder="your site manager's email...">
          </div>
          <div class="login-form-field-name">Password</div>
          <div>
            <input class="password" type="password">
          </div>
          <div class="login-form-footer">
            <button class="login-btn">Login</button>
          </div>
        </div>`;
        if (!isLogin) {
            this.emailInput = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'input.email');
            this.passwordInput = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'input.password');
            this.loginBtn = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'button.login-btn');
            this.loginBtn.addEventListener('click', e => {
                void this.login();
            });
            this.emailInput.addEventListener('keydown', (e) => {
                if (e.key === 'Enter') {
                    void this.login();
                }
            });
            this.passwordInput.addEventListener('keydown', (e) => {
                if (e.key === 'Enter') {
                    void this.login();
                }
            });
        }
    }
}
exports.OauthClientOwnerLogin = OauthClientOwnerLogin;
const defineOauthClientOwnerLogin = (name) => (0, lib_es2015_1.defineIfUndefined)(name, OauthClientOwnerLogin);
exports.defineOauthClientOwnerLogin = defineOauthClientOwnerLogin;
