"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineMemberEdit = exports.MemberEdit = void 0;
const apis_1 = require("./apis");
const lib_es2015_1 = require("@hodol/web-component-helper/lib-es2015");
const lib_es2015_2 = require("@hodol/complex-inputs/lib-es2015");
const lib_es2015_3 = require("@hodol/dom-helper/lib-es2015");
const apis_2 = require("../oauth-client/apis");
const apis_3 = require("../resource-owner/apis");
const apis_4 = require("../member-group/apis");
const Constants = require("./constants");
class MemberEdit extends HTMLElement {
    constructor() {
        super();
        this.memberKey = (0, lib_es2015_3.getAttributeOrThrowError)(this, 'member-key');
        this.apis = new apis_1.default();
        this.router = (0, lib_es2015_1.useRouter)();
        this.oauthClientApis = new apis_2.default();
        this.resourceOwnerApis = new apis_3.default();
        this.memberGroupApis = new apis_4.default();
        this.style.setProperty('display', 'block');
    }
    connectedCallback() {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            this.innerHTML = 'Loading data ...';
            const entity = yield this.apis.getByKey({ _key: this.memberKey });
            if (entity === null) {
                alert('fail to load oauth client owner data.');
                return;
            }
            const oauthClientId = yield this.oauthClientApis.getOauthClientIdByKey(entity.oauth_client_key);
            if (oauthClientId === null) {
                alert('fail to load oauth client id');
                return;
            }
            const globalIdentifiers = yield this.resourceOwnerApis.getGlobalIdentifiersByKey(entity.resource_owner_key);
            const memberGroupListPage = yield this.memberGroupApis.list({
                page_no: 0,
                page_size: 0,
                query: '',
                oauth_client_key: entity.oauth_client_key,
            });
            if (memberGroupListPage === null) {
                alert('fail to load member group list page');
                return;
            }
            const memberGroupList = memberGroupListPage.items;
            this.innerHTML = `
    <div class="edit-form">
      <div class="edit-form-field-name">_key</div>
      <div class="edit-form-field-value">${this.memberKey}</div>
      
      <div class="edit-form-field-name">Oauth Client Id</div>
      <div class="edit-form-field-value">${oauthClientId} (${entity.oauth_client_key})</div>
      
      <div class="edit-form-field-name">Resource Owner Global Identifier</div>
      <div class="edit-form-field-value">${globalIdentifiers
                .filter(gi => entity.oauth_info.resource_server === gi.resource_server)
                .map(gi => `${gi.identifier} (${gi.resource_server})`)
                .join('<br>\n')}</div>
      
      <div class="edit-form-field-name">Member Group Names</div>
      <div class="edit-form-field-value">
        <div><search-selector-list class="member-group-keys"></search-selector-list></div>
      </div>
      
      <div class="edit-form-field-name">Nick</div>
      <div class="edit-form-field-value"><input class="nick" /></div>
      
      <div class="edit-form-field-name">Pre-title</div>
      <div class="edit-form-field-value"><input class="pre-title" /></div>
      
      <div class="edit-form-field-name">Post-title</div>
      <div class="edit-form-field-value"><input class="post-title" /></div>
      
      <div class="edit-form-field-name">Oauth Access Token</div>
      <div class="edit-form-field-value"><textarea class="oauth-info-access-token"></textarea></div>
      
      <div class="edit-form-field-name">Oauth Token Type</div>
      <div class="edit-form-field-value"><input class="oauth-info-token-type" /></div>
      
      <div class="edit-form-field-name">Oauth Expired At</div>
      <div class="edit-form-field-value"><input class="oauth-info-expire-at" /></div>
      
      <div class="edit-form-field-name">Oauth Refresh Token</div>
      <div class="edit-form-field-value"><input class="oauth-info-refresh-token" /></div>
      
      <div class="edit-form-field-name">Oauth Resource Server</div>
      <div class="edit-form-field-value"><input class="oauth-info-resource-server" /></div>
      
      <div class="edit-form-field-name">Extra</div>
      <div class="edit-form-field-value">
        <div><input-map class="extra" max-key-textarea-width="200" max-value-textarea-width="500"></input-map></div>
      </div>
      
      <div class="edit-form-footer"><button class="submit-btn">Submit</button></div>
    </div>`;
            this.memberGroupKeySelectorList = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'search-selector-list.member-group-keys');
            this.memberGroupKeySelectorList.init(entity.member_group_keys.map(memberGroupKey => {
                const filtered = memberGroupList.filter(mg => mg._key === memberGroupKey);
                if (filtered.length !== 1) {
                    throw Error(`member group of key, ${memberGroupKey} is not unique`);
                }
                return {
                    value: memberGroupKey,
                    displayText: `${filtered[0].name}`,
                    description: '',
                };
            }), this.memberGroupApis.listForSearchSelector);
            this.nickInput = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'input.nick');
            this.nickInput.value = entity.nick;
            this.preTitleInput = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'input.pre-title');
            this.preTitleInput.value = entity.pre_title;
            this.postTitleInput = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'input.post-title');
            this.postTitleInput.value = entity.post_title;
            this.oauthInfoAccessTokenTextarea = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'textarea.oauth-info-access-token');
            this.oauthInfoAccessTokenTextarea.value = entity.oauth_info.access_token;
            this.oauthInfoTokenTypeInput = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'input.oauth-info-token-type');
            this.oauthInfoTokenTypeInput.value = entity.oauth_info.token_type;
            this.oauthInfoExpireAtInput = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'input.oauth-info-expire-at');
            this.oauthInfoExpireAtInput.value = entity.oauth_info.expire_at;
            this.oauthInfoRefreshTokenInput = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'input.oauth-info-refresh-token');
            this.oauthInfoRefreshTokenInput.value = entity.oauth_info.refresh_token;
            this.oauthInfoResourceServerInput = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'input.oauth-info-resource-server');
            this.oauthInfoResourceServerInput.value = entity.oauth_info.resource_server;
            this.extraInputMap = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'input-map.extra');
            this.extraInputMap.value = (_a = entity.extra) !== null && _a !== void 0 ? _a : {};
            (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'button.submit-btn').addEventListener('click', e => {
                void (() => __awaiter(this, void 0, void 0, function* () {
                    const ans = confirm(`Are you sure to update ${Constants.subjectName} info?`);
                    if (!ans)
                        return;
                    const apiResp = yield this.apis.update({
                        _key: this.memberKey,
                        oauth_client_key: entity.oauth_client_key,
                        resource_owner_key: entity.resource_owner_key,
                        nick: this.nickInput.value,
                        pre_title: this.preTitleInput.value,
                        post_title: this.postTitleInput.value,
                        oauth_info: {
                            access_token: this.oauthInfoAccessTokenTextarea.value,
                            token_type: this.oauthInfoTokenTypeInput.value,
                            expire_at: this.oauthInfoExpireAtInput.value,
                            refresh_token: this.oauthInfoRefreshTokenInput.value,
                            resource_server: this.oauthInfoResourceServerInput.value,
                        },
                        member_group_keys: this.memberGroupKeySelectorList.value,
                        extra: this.extraInputMap.value,
                    });
                    if (apiResp === null) {
                        return;
                    }
                    alert('Updated Member Successfully!');
                    entity.nick = apiResp.nick;
                    entity.pre_title = apiResp.pre_title;
                    entity.post_title = apiResp.post_title;
                    entity.oauth_info = apiResp.oauth_info;
                    entity.member_group_keys = apiResp.member_group_keys;
                    entity.extra = apiResp.extra;
                }))();
            });
        });
    }
}
exports.MemberEdit = MemberEdit;
const defineMemberEdit = (name) => {
    (0, lib_es2015_2.defineSearchSelector)('search-selector');
    (0, lib_es2015_2.defineSearchSelectorList)('search-selector-list');
    (0, lib_es2015_2.defineInputMap)('input-map');
    (0, lib_es2015_1.defineIfUndefined)(name, MemberEdit);
};
exports.defineMemberEdit = defineMemberEdit;
