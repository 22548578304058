"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineOauthClientEdit = exports.OauthClientEdit = void 0;
const apis_1 = require("./apis");
const lib_es2015_1 = require("@hodol/web-component-helper/lib-es2015");
const oauth_context_form_1 = require("./oauth-context-form");
const lib_es2015_2 = require("@hodol/dom-helper/lib-es2015");
const apis_2 = require("../oauth-client-owner/apis");
const Constants = require("./constants");
class OauthClientEdit extends HTMLElement {
    constructor() {
        super();
        this.apis = new apis_1.default();
        this.oauthClientKey = (0, lib_es2015_2.getAttributeOrThrowError)(this, 'oauth-client-key');
        this.oauthClientOwnerApis = new apis_2.default();
        this.oauthContextForms = {};
        this.style.setProperty('display', 'block');
    }
    connectedCallback() {
        return __awaiter(this, void 0, void 0, function* () {
            this.innerHTML = 'Loading data ...';
            const entity = yield this.apis.getByKey({ _key: this.oauthClientKey });
            if (entity === null) {
                alert('fail to load oauth client owner data.');
                return;
            }
            const oauthClientOwnerEmail = yield this.oauthClientOwnerApis.getEmailByKey(entity.oauth_client_owner_key);
            if (oauthClientOwnerEmail === null) {
                alert('fail to load oauth client owner email');
                return;
            }
            this.innerHTML = `
    <div class="edit-form">
      <div class="edit-form-field-name">_key</div>
      <div>${this.oauthClientKey}</div>
      
      <div class="edit-form-field-name">Oauth Client Owner Email</div>
      <div>${oauthClientOwnerEmail} (${entity.oauth_client_owner_key})</div>
      
      <div class="edit-form-field-name">Oauth Client Id</div>
      <div>${entity.id}</div>
      
      <div class="edit-form-field-name">Password</div>
      <div><input type="password" class="password" /></div>
      
      <div class="edit-form-field-name">Confirm Password</div>
      <div><input type="password" class="password-confirm" /></div>
      
      <div class="edit-form-field-name">Member Extra Keys</div>
      <div><input-list class="member-extra-key"></input-list></div>
    </div>
    
    <div class="oauth-context-form-container"></div>
    <div class="oauth-context-btn-container"><button class="append-oauth-context-btn">Append Oauth Resource Server</button></div>
    
    <div class="edit-form-footer"><button class="submit-btn">Submit</button></div>`;
            this.passwordInput = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'input.password');
            this.memberExtraKeyInputList = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'input-list.member-extra-key');
            this.memberExtraKeyInputList.value = entity.member_extra_keys;
            const oauthContextFormContainer = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'div.oauth-context-form-container');
            Object.entries(entity.oauth_contexts).forEach(([resourceServer, oauthContext]) => {
                const oauthCtxForm = this.oauthContextForms[resourceServer];
                if (oauthCtxForm !== undefined && oauthCtxForm !== null) {
                    alert(`The resource server of name ${resourceServer} already exists`);
                    return;
                }
                if (oauthContext === null) {
                    alert(`the oauth context of ${resourceServer} is null!`);
                    return;
                }
                const form = new oauth_context_form_1.OauthContextForm();
                this.oauthContextForms[resourceServer] = form;
                form.setAttribute('resource-server', resourceServer);
                form.addEventListener('on-delete-btn-click', e => {
                    (0, lib_es2015_2.getParentElementOrThrowError)(form).removeChild(form);
                    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                    delete this.oauthContextForms[resourceServer];
                });
                form.value = oauthContext;
                oauthContextFormContainer.appendChild(form);
            });
            (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'button.append-oauth-context-btn').addEventListener('click', e => {
                const resourceServer = prompt('Put oauth resource server name, e.g. google, facebook, kakao, naver and etc...');
                if (resourceServer === null || resourceServer === '') {
                    return;
                }
                const oauthCtxForm = this.oauthContextForms[resourceServer];
                if (oauthCtxForm !== undefined && oauthCtxForm !== null) {
                    alert(`The resource server of name ${resourceServer} already exists`);
                    return;
                }
                const form = new oauth_context_form_1.OauthContextForm();
                this.oauthContextForms[resourceServer] = form;
                form.setAttribute('resource-server', resourceServer);
                form.addEventListener('on-delete-btn-click', e => {
                    (0, lib_es2015_2.getParentElementOrThrowError)(form).removeChild(form);
                    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                    delete this.oauthContextForms[resourceServer];
                });
                form.value = {
                    resource_server: resourceServer,
                    background_color: '#fffffff',
                    font_color: '#000000',
                    client_id: '',
                    client_secret: '',
                    endpoint: {
                        auth_style: 1,
                        token_url: '',
                        auth_url: '',
                    },
                    redirect_url: '',
                    scopes: [],
                };
                oauthContextFormContainer.appendChild(form);
            });
            const passwordConfirmInput = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'input.password-confirm');
            (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'button.submit-btn').addEventListener('click', e => {
                if (this.passwordInput.value === '') {
                    alert(`put ${Constants.subjectName} password`);
                    return;
                }
                if (this.passwordInput.value !== passwordConfirmInput.value) {
                    alert(`${Constants.subjectName} passwords are different`);
                    return;
                }
                const ans = confirm(`Are you sure to update ${Constants.subjectName} info?`);
                if (!ans)
                    return;
                void (() => __awaiter(this, void 0, void 0, function* () {
                    const apiResp = yield this.apis.update({
                        _key: entity._key,
                        oauth_client_owner_key: entity.oauth_client_owner_key,
                        id: entity.id,
                        password: this.passwordInput.value,
                        oauth_contexts: Object.fromEntries(Object.entries(this.oauthContextForms).map(([resourceServer, form]) => [resourceServer, form.value])),
                        member_extra_keys: this.memberExtraKeyInputList.value,
                    });
                    if (apiResp === null) {
                        return;
                    }
                    alert(`updated ${Constants.subjectName}, successfully!`);
                    entity._key = apiResp._key;
                    entity.oauth_client_owner_key = apiResp.oauth_client_owner_key;
                    entity.id = apiResp.id;
                    entity.password = '';
                    entity.oauth_contexts = apiResp.oauth_contexts;
                    entity.member_extra_keys = apiResp.member_extra_keys;
                    passwordConfirmInput.value = '';
                }))();
            });
        });
    }
}
exports.OauthClientEdit = OauthClientEdit;
const defineOauthClientEdit = (name) => {
    (0, oauth_context_form_1.defineOauthContextForm)('oauth-context-form');
    (0, lib_es2015_1.defineIfUndefined)(name, OauthClientEdit);
};
exports.defineOauthClientEdit = defineOauthClientEdit;
