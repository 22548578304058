"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineResourceOwnerRegister = exports.ResourceOwnerRegister = void 0;
const apis_1 = require("./apis");
const lib_es2015_1 = require("@hodol/dom-helper/lib-es2015");
const lib_es2015_2 = require("@hodol/web-component-helper/lib-es2015");
class ResourceOwnerRegister extends HTMLElement {
    constructor() {
        super();
        this.apis = new apis_1.default();
        this.router = (0, lib_es2015_2.useRouter)();
        this.style.setProperty('display', 'block');
    }
    connectedCallback() {
        return __awaiter(this, void 0, void 0, function* () {
            this.innerHTML = `
    <div class="register-form">
      <div class="register-form-field-name">Resource Server</div>
      <div><input type="text" class="resource-server" placeholder="resource server name"/></div>
      <div class="register-form-field-name">Identifier</div>
      <div><input type="text" class="identifier" placeholder="member identifier in resource server"/></div>
      <div class="register-form-footer">
        <button class="submit-btn">Submit</button>
      </div>
    </div>
    `;
            const resourceServerInput = (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'input.resource-server');
            const identifierInput = (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'input.identifier');
            (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'button.submit-btn').addEventListener('click', e => {
                if (resourceServerInput.value === '') {
                    alert(`put resource server`);
                    return;
                }
                if (identifierInput.value === '') {
                    alert(`put identifier`);
                    return;
                }
                void (() => __awaiter(this, void 0, void 0, function* () {
                    const apiResp = yield this.apis.insert({
                        _key: '',
                        global_identifiers: [
                            {
                                resource_server: resourceServerInput.value,
                                identifier: identifierInput.value,
                            },
                        ],
                        email: '', // TODO: Remove
                    });
                    if (apiResp === null) {
                        return;
                    }
                    alert(`Created resource owner successfully!`);
                }))();
            });
        });
    }
}
exports.ResourceOwnerRegister = ResourceOwnerRegister;
const defineResourceOwnerRegister = (name) => (0, lib_es2015_2.defineIfUndefined)(name, ResourceOwnerRegister);
exports.defineResourceOwnerRegister = defineResourceOwnerRegister;
