"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineOauthContextForm = exports.OauthContextForm = void 0;
const lib_es2015_1 = require("@hodol/complex-inputs/lib-es2015");
const lib_es2015_2 = require("@hodol/web-component-helper/lib-es2015");
const lib_es2015_3 = require("@hodol/dom-helper/lib-es2015");
class OauthContextForm extends HTMLElement {
    constructor() {
        super();
        this.resourceServer = '';
        this.style.setProperty('display', 'grid');
        this.style.setProperty('grid-template-columns', 'auto 1fr');
        this.innerHTML = `
    <div><button class="delete-btn">Delete</button></div>
    <div></div>
    
    <div class="oauth-context-field-name">Resource Server Name</div>
    <div class="oauth-context-field-value resource-server-name"></div>
    
    <div class="oauth-context-field-name">Background Color</div>
    <div class="oauth-context-field-value"><input class="background-color" /></div>
    
    <div class="oauth-context-field-name">Font Color</div>
    <div class="oauth-context-field-value"><input class="font-color" /></div>
    
    <div class="oauth-context-field-name">Client Id (for resource server)</div>
    <div class="oauth-context-field-value"><input class="client-id" /></div>
    
    <div class="oauth-context-field-name">Client Secret</div>
    <div class="oauth-context-field-value"><input class="client-secret" /></div>
    
    <div class="oauth-context-field-name">Endpoint: Auth URL</div>
    <div class="oauth-context-field-value"><input class="endpoint-auth-url" /></div>
    
    <div class="oauth-context-field-name">Endpoint: Token URL</div>
    <div class="oauth-context-field-value"><input class="endpoint-token-url" /></div>
    
    <div class="oauth-context-field-name">Endpoint: Auth Style</div>
    <div class="oauth-context-field-value"><input type="number" class="endpoint-auth-style" /></div>
    
    <div class="oauth-context-field-name">Redirect URL</div>
    <div class="oauth-context-field-value"><input class="redirect-url" /></div>
    
    <div class="oauth-context-field-name">Scopes</div>
    <div class="oauth-context-field-value">
      <div>
        <input-list class="scopes" max-textarea-width="500"></input-list>
      </div>
    </div>
    `;
        this.resourceServerNameContainer = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'div.resource-server-name');
        this.backgroundColorInput = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'input.background-color');
        this.fontColorInput = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'input.font-color');
        this.clientIdInput = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'input.client-id');
        this.clientSecretInput = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'input.client-secret');
        this.endpointAuthUrlInput = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'input.endpoint-auth-url');
        this.endpointTokenUrlInput = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'input.endpoint-token-url');
        this.endpointAuthStyleInput = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'input.endpoint-auth-style');
        this.redirectUrlInput = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'input.redirect-url');
        this.scopesInputList = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'input-list.scopes');
        (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'button.delete-btn').addEventListener('click', e => {
            this.dispatchEvent(new CustomEvent('on-delete-btn-click', {
                detail: this.resourceServer,
            }));
        });
    }
    connectedCallback() {
        this.resourceServer = (0, lib_es2015_3.getAttributeOrThrowError)(this, 'resource-server');
        this.resourceServerNameContainer.innerHTML = this.resourceServer;
    }
    set value(oauthContextEntity) {
        this.resourceServer = oauthContextEntity.resource_server;
        this.backgroundColorInput.value = oauthContextEntity.background_color;
        this.fontColorInput.value = oauthContextEntity.font_color;
        this.clientIdInput.value = oauthContextEntity.client_id;
        this.clientSecretInput.value = oauthContextEntity.client_secret;
        this.endpointAuthUrlInput.value = oauthContextEntity.endpoint.auth_url;
        this.endpointTokenUrlInput.value = oauthContextEntity.endpoint.token_url;
        this.endpointAuthStyleInput.value = String(oauthContextEntity.endpoint.auth_style);
        this.redirectUrlInput.value = oauthContextEntity.redirect_url;
        this.scopesInputList.value = oauthContextEntity.scopes;
    }
    get value() {
        return {
            resource_server: this.resourceServer,
            background_color: this.backgroundColorInput.value,
            font_color: this.fontColorInput.value,
            client_id: this.clientIdInput.value,
            client_secret: this.clientSecretInput.value,
            endpoint: {
                auth_url: this.endpointAuthUrlInput.value,
                token_url: this.endpointTokenUrlInput.value,
                auth_style: Number(this.endpointAuthStyleInput.value),
            },
            redirect_url: this.redirectUrlInput.value,
            scopes: this.scopesInputList.value,
        };
    }
}
exports.OauthContextForm = OauthContextForm;
const defineOauthContextForm = (name) => {
    (0, lib_es2015_1.defineInputList)('input-list');
    (0, lib_es2015_2.defineIfUndefined)(name, OauthContextForm);
};
exports.defineOauthContextForm = defineOauthContextForm;
