"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineOauthClientOwnerLogout = exports.OauthClientOwnerLogout = void 0;
const lib_es2015_1 = require("@hodol/web-component-helper/lib-es2015");
const apis_1 = require("./apis");
class OauthClientOwnerLogout extends HTMLElement {
    constructor() {
        super();
        this.apis = new apis_1.default();
        this.style.setProperty('display', 'block');
        void (() => __awaiter(this, void 0, void 0, function* () {
            const resp = yield this.apis.logout();
            if (resp === null) {
                this.innerHTML = `You failed to logout!`;
                return;
            }
            location.href = resp;
        }))();
    }
}
exports.OauthClientOwnerLogout = OauthClientOwnerLogout;
const defineOauthClientOwnerLogout = (name) => (0, lib_es2015_1.defineIfUndefined)(name, OauthClientOwnerLogout);
exports.defineOauthClientOwnerLogout = defineOauthClientOwnerLogout;
