"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineOauthClientOwnerRegister = exports.OauthClientOwnerRegister = void 0;
const apis_1 = require("./apis");
const lib_es2015_1 = require("@hodol/dom-helper/lib-es2015");
const lib_es2015_2 = require("@hodol/web-component-helper/lib-es2015");
const Constants = require("./constants");
class OauthClientOwnerRegister extends HTMLElement {
    constructor() {
        super();
        this.apis = new apis_1.default();
        this.router = (0, lib_es2015_2.useRouter)();
        this.style.setProperty('display', 'block');
    }
    connectedCallback() {
        return __awaiter(this, void 0, void 0, function* () {
            this.innerHTML = `
    <div class="register-form">
      <div class="register-form-field-name">email</div>
      <div><input type="text" class="email" /></div>
      <div class="register-form-field-name">password</div>
      <div><input type="password" class="password" /></div>
      <div class="register-form-field-name">password confirm</div>
      <div><input type="password" class="password-confirm" /></div>
      <div class="register-form-footer">
        <button class="submit-btn">Submit</button>
      </div>
    </div>
    `;
            const emailInput = (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'input.email');
            const passwordInput = (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'input.password');
            const passwordConfirmInput = (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'input.password-confirm');
            (0, lib_es2015_1.getQuerySelectOrThrowError)(this, 'button.submit-btn').addEventListener('click', e => {
                if (passwordInput.value === '') {
                    alert(`put ${Constants.subjectName} password`);
                    return;
                }
                if (passwordInput.value !== passwordConfirmInput.value) {
                    alert(`${Constants.subjectName} passwords are different`);
                    return;
                }
                if (emailInput.value === '') {
                    alert(`put ${Constants.subjectName} email`);
                    return;
                }
                void (() => __awaiter(this, void 0, void 0, function* () {
                    const apiResp = yield this.apis.insert({
                        _key: '',
                        email: emailInput.value,
                        password: passwordInput.value,
                    });
                    if (apiResp === null) {
                        return;
                    }
                    alert(`Sign up successfully!`);
                    this.router.push(`/web/${Constants.routerPathGroup}/login`);
                }))();
            });
        });
    }
}
exports.OauthClientOwnerRegister = OauthClientOwnerRegister;
const defineOauthClientOwnerRegister = (name) => (0, lib_es2015_2.defineIfUndefined)(name, OauthClientOwnerRegister);
exports.defineOauthClientOwnerRegister = defineOauthClientOwnerRegister;
