"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineMainFrameNavigator = exports.MainFrameNavigator = void 0;
const lib_es2015_1 = require("@hodol/web-component-helper/lib-es2015");
const lib_es2015_2 = require("@hodol/searchable-tree-menu/lib-es2015");
const OauthClientOwnerConstant = require("../oauth-client-owner/constants");
const OauthClientConstant = require("../oauth-client/constants");
const ResourceOwnerConstant = require("../resource-owner/constants");
const MemberGroupConstant = require("../member-group/constants");
const MemberConstant = require("../member/constants");
class MainFrameNavigator extends HTMLElement {
    constructor() {
        super();
        this.router = (0, lib_es2015_1.useRouter)();
        const isMobile = window.matchMedia(`(max-width: 600px)`).matches;
        const menuCfg = {
            name: ``,
            children: [
                {
                    name: `Oauth Client Owner`,
                    onMenuClick: () => {
                        this.router.push(`/web/${OauthClientOwnerConstant.routerPathGroup}/list?page_no=1&page_size=10&query=&email=`);
                        if (isMobile)
                            this.style.setProperty('display', 'none');
                    },
                },
                {
                    name: `Oauth Client`,
                    onMenuClick: () => {
                        this.router.push(`/web/${OauthClientConstant.routerPathGroup}/list?page_no=1&page_size=10&query=&oauth_client_owner_key=`);
                        if (isMobile)
                            this.style.setProperty('display', 'none');
                    },
                },
                {
                    name: `Resource Owner`,
                    onMenuClick: () => {
                        this.router.push(`/web/${ResourceOwnerConstant.routerPathGroup}/list?page_no=1&page_size=10&query=`);
                        if (isMobile)
                            this.style.setProperty('display', 'none');
                    },
                },
                {
                    name: `Member Group`,
                    onMenuClick: () => {
                        this.router.push(`/web/${MemberGroupConstant.routerPathGroup}/list?page_no=1&page_size=10&query=&oauth_client_key=`);
                        if (isMobile)
                            this.style.setProperty('display', 'none');
                    },
                },
                {
                    name: `Member`,
                    onMenuClick: () => {
                        this.router.push(`/web/${MemberConstant.routerPathGroup}/list?page_no=1&page_size=10&query=&oauth_client_key=&resource_owner_key=&member_group_keys=`);
                        if (isMobile)
                            this.style.setProperty('display', 'none');
                    },
                },
            ],
            isFold: false,
        };
        const treeMenu = new lib_es2015_2.MenuNode(menuCfg, 0, undefined);
        this.appendChild(treeMenu);
        if (isMobile)
            this.style.setProperty('display', 'none');
    }
}
exports.MainFrameNavigator = MainFrameNavigator;
const defineMainFrameNavigator = (name) => {
    (0, lib_es2015_2.define)(`searchable-tree-menu`);
    (0, lib_es2015_1.defineIfUndefined)(name, MainFrameNavigator);
};
exports.defineMainFrameNavigator = defineMainFrameNavigator;
