"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineViews = exports.routes = void 0;
const home_page_1 = require("../main/home-page");
const OauthClientOwnerConstant = require("../oauth-client-owner/constants");
const login_1 = require("../oauth-client-owner/login");
const list_1 = require("../oauth-client-owner/list");
const logout_1 = require("../oauth-client-owner/logout");
const edit_1 = require("../oauth-client-owner/edit");
const register_1 = require("../oauth-client-owner/register");
const OauthClientConstant = require("../oauth-client/constants");
const list_2 = require("../oauth-client/list");
const edit_2 = require("../oauth-client/edit");
const register_2 = require("../oauth-client/register");
const ResourceOwnerConstant = require("../resource-owner/constants");
const list_3 = require("../resource-owner/list");
const edit_3 = require("../resource-owner/edit");
const register_3 = require("../resource-owner/register");
const MemberGroupConstant = require("../member-group/constants");
const list_4 = require("../member-group/list");
const edit_4 = require("../member-group/edit");
const register_4 = require("../member-group/register");
const MemberConstant = require("../member/constants");
const list_5 = require("../member/list");
const edit_5 = require("../member/edit");
const register_5 = require("../member/register");
const MetadataConstant = require("../metadata/constants");
const edit_6 = require("../metadata/edit");
exports.routes = [
    {
        path: '/web',
        tag: 'home-page',
        pathParamNames: [],
        queryParamNames: [],
    },
    {
        path: `/web/${OauthClientOwnerConstant.routerPathGroup}/login`,
        tag: 'oauth-client-owner-login',
        pathParamNames: [],
        queryParamNames: [],
    },
    {
        path: `/web/${OauthClientOwnerConstant.routerPathGroup}/logout`,
        tag: 'oauth-client-owner-logout',
        pathParamNames: [],
        queryParamNames: [],
    },
    {
        path: `/web/${OauthClientOwnerConstant.routerPathGroup}/list`,
        tag: 'oauth-client-owner-list',
        pathParamNames: [],
        queryParamNames: ['page_no', 'page_size', 'query', 'email'],
    },
    {
        path: `/web/${OauthClientOwnerConstant.routerPathGroup}/register`,
        tag: 'oauth-client-owner-register',
        pathParamNames: [],
        queryParamNames: [],
    },
    {
        path: `/web/${OauthClientOwnerConstant.routerPathGroup}/edit/:oauth-client-owner-key`,
        tag: 'oauth-client-owner-edit',
        pathParamNames: ['oauth-client-owner-key'],
        queryParamNames: [],
    },
    {
        path: `/web/${OauthClientConstant.routerPathGroup}/list`,
        tag: 'oauth-client-list',
        pathParamNames: [],
        queryParamNames: ['page_no', 'page_size', 'query', 'oauth_client_owner_key'],
    },
    {
        path: `/web/${OauthClientConstant.routerPathGroup}/register`,
        tag: 'oauth-client-register',
        pathParamNames: [],
        queryParamNames: [],
    },
    {
        path: `/web/${OauthClientConstant.routerPathGroup}/edit/:oauth-client-key`,
        tag: 'oauth-client-edit',
        pathParamNames: ['oauth-client-key'],
        queryParamNames: [],
    },
    {
        path: `/web/${ResourceOwnerConstant.routerPathGroup}/list`,
        tag: 'resource-owner-list',
        pathParamNames: [],
        queryParamNames: ['page_no', 'page_size', 'query'],
    },
    {
        path: `/web/${ResourceOwnerConstant.routerPathGroup}/register`,
        tag: 'resource-owner-register',
        pathParamNames: [],
        queryParamNames: [],
    },
    {
        path: `/web/${ResourceOwnerConstant.routerPathGroup}/edit/:resource-owner-key`,
        tag: 'resource-owner-edit',
        pathParamNames: ['resource-owner-key'],
        queryParamNames: [],
    },
    {
        path: `/web/${MemberGroupConstant.routerPathGroup}/list`,
        tag: 'member-group-list',
        pathParamNames: [],
        queryParamNames: ['page_no', 'page_size', 'query', 'oauth_client_key'],
    },
    {
        path: `/web/${MemberGroupConstant.routerPathGroup}/register`,
        tag: 'member-group-register',
        pathParamNames: [],
        queryParamNames: [],
    },
    {
        path: `/web/${MemberGroupConstant.routerPathGroup}/edit/:member-group-key`,
        tag: 'member-group-edit',
        pathParamNames: ['member-group-key'],
        queryParamNames: [],
    },
    {
        path: `/web/${MemberConstant.routerPathGroup}/list`,
        tag: 'member-list',
        pathParamNames: [],
        queryParamNames: ['page_no', 'page_size', 'query', 'oauth_client_key', 'resource_owner_key', 'member_group_keys'],
    },
    {
        path: `/web/${MemberConstant.routerPathGroup}/register`,
        tag: 'member-register',
        pathParamNames: [],
        queryParamNames: [],
    },
    {
        path: `/web/${MemberConstant.routerPathGroup}/edit/:member-key`,
        tag: 'member-edit',
        pathParamNames: ['member-key'],
        queryParamNames: [],
    },
    {
        path: `/web/${MetadataConstant.routerPathGroup}/edit`,
        tag: 'metadata-edit',
        pathParamNames: [],
        queryParamNames: [],
    },
];
const defineViews = () => {
    (0, home_page_1.defineHomePage)('home-page');
    (0, login_1.defineOauthClientOwnerLogin)('oauth-client-owner-login');
    (0, logout_1.defineOauthClientOwnerLogout)('oauth-client-owner-logout');
    (0, list_1.defineOauthClientOwnerList)('oauth-client-owner-list');
    (0, register_1.defineOauthClientOwnerRegister)('oauth-client-owner-register');
    (0, edit_1.defineOauthClientOwnerEdit)('oauth-client-owner-edit');
    (0, list_2.defineOauthClientList)('oauth-client-list');
    (0, edit_2.defineOauthClientEdit)('oauth-client-edit');
    (0, register_2.defineOauthClientRegister)('oauth-client-register');
    (0, list_3.defineResourceOwnerList)('resource-owner-list');
    (0, edit_3.defineResourceOwnerEdit)('resource-owner-edit');
    (0, register_3.defineResourceOwnerRegister)('resource-owner-register');
    (0, list_4.defineMemberGroupList)('member-group-list');
    (0, edit_4.defineMemberGroupEdit)('member-group-edit');
    (0, register_4.defineMemberGroupRegister)('member-group-register');
    (0, list_5.defineMemberList)('member-list');
    (0, edit_5.defineMemberEdit)('member-edit');
    (0, register_5.defineMemberRegister)('member-register');
    (0, edit_6.defineMetadataEdit)('metadata-edit');
};
exports.defineViews = defineViews;
