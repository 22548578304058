"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineOauthClientOwnerList = exports.OauthClientOwnerList = void 0;
const lib_es2015_1 = require("@hodol/web-component-helper/lib-es2015");
const lib_es2015_2 = require("@hodol/dom-helper/lib-es2015");
const lib_es2015_3 = require("@hodol/web-component-tables/lib-es2015");
const apis_1 = require("./apis");
const lib_es2015_4 = require("@hodol/complex-inputs/lib-es2015");
const Constants = require("./constants");
class OauthClientOwnerList extends HTMLElement {
    constructor() {
        super();
        this.router = (0, lib_es2015_1.useRouter)();
        this.apis = new apis_1.default();
        this.pageNo = Number.parseInt((0, lib_es2015_2.getAttributeOrDefault)(this, 'page_no', '1'));
        this.pageSize = Number.parseInt((0, lib_es2015_2.getAttributeOrDefault)(this, 'page_size', '10'));
        this.query = (0, lib_es2015_2.getAttributeOrDefault)(this, 'query', '');
        this.email = (0, lib_es2015_2.getAttributeOrDefault)(this, 'email', '');
        this.fetchData = () => __awaiter(this, void 0, void 0, function* () {
            const request = {
                page_no: this.pageNo,
                page_size: this.pageSize,
                query: this.query,
                email: this.email,
            };
            const jointedEntityListPage = yield this.apis.list(request);
            if (jointedEntityListPage === null) {
                this.table.setData([]);
                return;
            }
            console.log(`setup data in table`);
            this.table.setData(jointedEntityListPage.items);
            const pageNavigator = (0, lib_es2015_2.buildNodeByOuterHTML)(`<page-navigator id="page-navigator" page-no="${this.pageNo}" last-page-no="${jointedEntityListPage.last_page_no}"></page-navigator>`);
            pageNavigator.addEventListener('click-navigation-btn', (e) => {
                const targetPage = e.detail;
                const url = new URL(window.location.href);
                url.searchParams.set('page_no', `${targetPage}`);
                url.searchParams.set('page_size', `${this.pageSize}`);
                this.router.push(url.toString());
            });
            const currentPageNavigator = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'page-navigator');
            (0, lib_es2015_2.getParentElementOrThrowError)(currentPageNavigator).replaceChild(pageNavigator, currentPageNavigator);
        });
        this.style.setProperty('display', 'block');
        this.innerHTML = `
      <div class="table-with-pageable">
        <div class="table-container">
          <div class="table-header">
            <input class="query">
            <button class="search">Search</button>
            <button class="reset">Reset</button>
            <button class="new">New</button>
          </div>
          <div class="list-container">
            <dynamic-table primary-key-name="_key" fixed-field-names="_key" field-names="email,buttons" is-show-checkbox="true"></dynamic-table>
          </div>
        </div>
        <div>
          <page-navigator page-no="1" last-page-no="10"></page-navigator>
        </div>
      </div>
    `;
        this.table = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, `dynamic-table`);
        this.table.createCell = (content, primaryKey, fieldName) => {
            const entity = content;
            if (fieldName === 'buttons') {
                const cell = (0, lib_es2015_2.buildNodeByOuterHTML)(`<div><button-list button-names="edit,delete"></button-list></div>`);
                (0, lib_es2015_2.getQuerySelectOrThrowError)(cell, 'button-list').addEventListener('click', (e) => {
                    const btnName = e.detail;
                    if (btnName === 'edit') {
                        this.router.push(`/web/${Constants.routerPathGroup}/edit/${primaryKey}`);
                    }
                    else if (btnName === 'delete') {
                        const answer = confirm(`Are you sure to delete ${Constants.subjectName}? key: ${primaryKey}, email: ${entity.email}`);
                        if (!answer)
                            return;
                        void (() => __awaiter(this, void 0, void 0, function* () {
                            const apiResp = yield this.apis.delete({ _key: primaryKey });
                            if (apiResp === null) {
                                return;
                            }
                            alert(`The ${Constants.subjectName} with key, ${apiResp._key} and email, ${apiResp.email} have been deleted!`);
                            yield this.fetchData();
                        }))();
                    }
                });
                return cell;
            }
            else {
                return (0, lib_es2015_2.buildNodeByOuterHTML)(`<div>${entity[fieldName]}</div>`);
            }
        };
        const searchBar = (0, lib_es2015_2.getQuerySelectOrThrowError)(this, `input.query`);
        searchBar.addEventListener('change', () => {
            this.query = searchBar.value;
        });
        searchBar.addEventListener('keydown', e => {
            if (e.key === 'Enter') {
                this.pageNo = 1;
                void (() => __awaiter(this, void 0, void 0, function* () {
                    yield this.fetchData();
                }))();
            }
        });
        (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'button.search').addEventListener('click', e => {
            this.pageNo = 1;
            void (() => __awaiter(this, void 0, void 0, function* () {
                yield this.fetchData();
            }))();
        });
        (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'button.reset').addEventListener('click', e => {
            this.query = '';
            void (() => __awaiter(this, void 0, void 0, function* () {
                yield this.fetchData();
            }))();
        });
        (0, lib_es2015_2.getQuerySelectOrThrowError)(this, 'button.new').addEventListener('click', e => {
            this.router.push(`/web/${Constants.routerPathGroup}/register`);
        });
    }
    connectedCallback() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.fetchData();
        });
    }
}
exports.OauthClientOwnerList = OauthClientOwnerList;
const defineOauthClientOwnerList = (name) => {
    (0, lib_es2015_3.defineDynamicTable)('dynamic-table');
    (0, lib_es2015_3.definePageNavigator)('page-navigator');
    (0, lib_es2015_4.defineButtonList)('button-list');
    (0, lib_es2015_1.defineIfUndefined)(name, OauthClientOwnerList);
};
exports.defineOauthClientOwnerList = defineOauthClientOwnerList;
