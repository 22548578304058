"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineMemberGroupRegister = exports.MemberGroupRegister = void 0;
const apis_1 = require("./apis");
const lib_es2015_1 = require("@hodol/web-component-helper/lib-es2015");
const lib_es2015_2 = require("@hodol/complex-inputs/lib-es2015");
const lib_es2015_3 = require("@hodol/dom-helper/lib-es2015");
const Constants = require("./constants");
const apis_2 = require("../oauth-client/apis");
class MemberGroupRegister extends HTMLElement {
    constructor() {
        super();
        this.apis = new apis_1.default();
        this.router = (0, lib_es2015_1.useRouter)();
        this.oauthClientApis = new apis_2.default();
        this.style.setProperty('display', 'block');
    }
    connectedCallback() {
        return __awaiter(this, void 0, void 0, function* () {
            this.innerHTML = `
    <div class="register-form">
      <div class="register-form-field-name">Oauth Client Id</div>
      <div><search-selector class="oauth-client-key"></search-selector></div>
      
      <div class="register-form-field-name">Name</div>
      <div><input class="name" /></div>
      
      <div class="register-form-footer"><button class="submit-btn">Submit</button></div>
    </div>`;
            this.oauthClientKeySelector = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'search-selector.oauth-client-key');
            this.oauthClientKeySelector.init({ value: '', displayText: '', description: '' }, this.oauthClientApis.listForSearchSelector);
            this.nameInput = (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'input.name');
            (0, lib_es2015_3.getQuerySelectOrThrowError)(this, 'button.submit-btn').addEventListener('click', e => {
                if (this.nameInput.value === '') {
                    alert(`put ${Constants.subjectName} name`);
                    return;
                }
                if (this.oauthClientKeySelector.value === '') {
                    alert(`put ${Constants.subjectName}'s Oauth Client Key.`);
                    return;
                }
                void (() => __awaiter(this, void 0, void 0, function* () {
                    const apiResp = yield this.apis.insert({
                        _key: '',
                        oauth_client_key: this.oauthClientKeySelector.value,
                        name: this.nameInput.value,
                    });
                    if (apiResp === null) {
                        return;
                    }
                    alert('Create Member Group Successfully!');
                }))();
            });
        });
    }
}
exports.MemberGroupRegister = MemberGroupRegister;
const defineMemberGroupRegister = (name) => {
    (0, lib_es2015_2.defineSearchSelector)('search-selector');
    (0, lib_es2015_1.defineIfUndefined)(name, MemberGroupRegister);
};
exports.defineMemberGroupRegister = defineMemberGroupRegister;
